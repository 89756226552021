import React, { useCallback, useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import { getStatusMetadata } from '../../../../common/NACommon'
import CngField from '../../../../components/cngcomponents/CngField'
import TinyChip from '../../../../components/aciacehighway/TinyChip'
import moment from 'moment'
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Switch,
  Typography,
  makeStyles
} from '@material-ui/core'

const {
  button: { CngButton, CngIconButton },
  CngDialog,
  form: {
    field: {
      CngSwitchField,
    },
  },
  CngGridItem
} = components

function SetResponseMessageDialog(props) {
  const {
    amendmentCodeOptions,
    onClose,
    onSaveHouseBill,
    onSubmitHouseBill,
    open,
    houseBills,
    translatedTextsObject
  } = props 

  const [updateMovementType, setUpdateMovementType] = useState(false)
  const [movementType, setMovementType] = useState("")
  const [changesSaved, setChangesSaved] = useState(false)
  const [changesSavedMessage, setChangesSavedMessage] = useState("")

  const useStyles = makeStyles((theme) => ({  
    switchTrack: {
        backgroundColor: "#00c0f5",
    },
    switchBase: {
        color: "#00c0f5",
        "&.Mui-disabled": {
            color: "#00c0f5"
        },
        "&.Mui-checked": {
            color: "#00c0f5"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#00c0f5",
        }
    },
    switchPrimary: {
        "&.Mui-checked": {
            color: "#00c0f5",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#00c0f5",
        },
    },
  }));

  const classes = useStyles()

  function updateMovementTypeOnChange(value) {
    setUpdateMovementType(value)

    if (value == false) {
      setMovementType("")
      houseBills.forEach((houseBill) => {
        houseBill.newMovementType = ""
      })
    }
  }

  function movementTypeOnChange(value) {
    setMovementType(value)
    setChangesSaved(false)

    houseBills.forEach((houseBill) => {
      houseBill.newMovementType = value
    })
  }

  function onSave(houseBills) {
    setChangesSaved(true)
    onSaveHouseBill(houseBills)
    setChangesSavedMessage("")
  }

  function onSubmit(houseBills) {
    if (movementType != "" && changesSaved != true) {
      setChangesSavedMessage(translatedTextsObject.changesSavedMessage)
    } else {
      onSubmitHouseBill(houseBills)
      onClose()
    }
  }

  const getStatusList = useCallback(() => {
    const result = []
    const statusList = [...new Set(houseBills.map((houseBill) => houseBill.status))]

    statusList.forEach((status) => {
      result.push({
        data: { code: status, ...getStatusMetadata(status) },
        houseBills: houseBills.filter((houseBill) => houseBill.status === status)
      })
    })

    return result
  }, [houseBills])

  return (
    <CngDialog
      dialogContent={
        <>
        <Grid container spacing={2}>
          {getStatusList().map((status, index) => (
            <Grid key={index} item xs={12}>
              <StatusItem
                amendmentCodeOptions={amendmentCodeOptions}
                index={index}
                status={status}
                translatedTextsObject={translatedTextsObject} />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper variant='outlined'>
              <Box padding={2}>
                <Grid alignItems='center' container spacing={2}>
                  <Grid item xs={4} md={4}>
                    <Typography>
                      {translatedTextsObject.updateMovementType}
                    </Typography>            
                  </Grid>          
                  <Grid item xs={8} md={8}>      
                    <FormControl variant='outlined' fullWidth> 
                      <Switch
                        name="movementTypeInd"
                        fullWidth
                        label="Movement Type"
                        onChange={(event) => updateMovementTypeOnChange(event.target.checked)}
                        checked={updateMovementType}
                        classes={{
                          track: classes.switchTrack,
                          switchBase: classes.switchBase,
                          colorPrimary: classes.switchPrimary,
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {updateMovementType && (
                  <Grid alignItems='center' container spacing={2}>
                      <Grid item xs={4} md={4}>
                        <Typography>
                          {translatedTextsObject.movementType}
                        </Typography>            
                      </Grid>                     
                      <Grid item xs={8} md={8}>
                        <FormControl variant='outlined' fullWidth>
                          <Select
                            label={translatedTextsObject.messageFunction}
                            inputProps={{
                              id: 'movement-type-label'
                            }}
                            native
                            onChange={(event) => movementTypeOnChange(event.target.value)}
                            size='small'
                            value={movementType}
                          >
                            <option value=''></option>                        
                            <option value='26'>
                              {translatedTextsObject.movementTypeFROB}
                            </option>
                            <option value='24'>
                              {translatedTextsObject.movementTypeImport}
                            </option>
                            <option value='27'>
                              {translatedTextsObject.movementTypeInBond}
                            </option>
                            <option value='23'>
                              {translatedTextsObject.movementTypeInTransit}
                            </option>
                          </Select>
                        </FormControl>
                      </Grid>
                  </Grid>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
        </>
      }
      dialogAction={
        <Grid container justify='space-between' spacing={2}>
          <Grid item xs={12}>
            <Grid container justify='flex-end' spacing={2}>
              <Typography style={{
                paddingRight: 10,
                fontSize: 12
                }}>
                {changesSavedMessage}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onClose} size='medium'>
              Back
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <Grid container justify='flex-end' spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='secondary'
                  onClick={() => onSave(houseBills)}
                  size='medium'
                >
                  Save
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={() => onSubmit(houseBills)}
                  size='medium'
                >
                  Submit to customs
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      dialogTitle='Set Submission Message & Movement Type'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default SetResponseMessageDialog

function StatusItem(props) {
  const {
    amendmentCodeOptions,
    index,
    status: { data, houseBills },
    translatedTextsObject
  } = props

  const [expanded, setExpanded] = useState(false)
  const [messageFunction, setMessageFunction] = useState('9')
  const [amendmentCode, setAmendmentCode] = useState(null)

  useEffect(() => {
    houseBills.forEach((houseBill) => {
      houseBill.messageFuncCode = messageFunction
      houseBill.amendmentCode = amendmentCode
    })
  }, [messageFunction, amendmentCode])

  return (
    <Paper variant='outlined'>
      <Box padding={2}>
        <Grid alignItems='center' container spacing={2}>
          <Grid item xs={12} md='auto'>
            <Typography variant='body1'>
              <Typography
                variant='inherit'
                style={{
                  color: data?.color || 'inherit',
                  fontWeight: 600
                }}
              >
                {data?.label}
              </Typography>
              &nbsp;
              <Typography variant='inherit'>({houseBills.length})</Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md>
            <Grid container spacing={2}>
              <Grid item xs={12} md>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor={`message-function-label-${index}`}>
                    {translatedTextsObject.messageFunction}
                  </InputLabel>
                  <Select
                    label={translatedTextsObject.messageFunction}
                    inputProps={{
                      id: `message-function-label-${index}`
                    }}
                    native
                    onChange={(event) => setMessageFunction(event.target.value)}
                    size='small'
                    value={messageFunction}
                  >
                    <option value='9'>
                      {translatedTextsObject.msgFuncOriginal}
                    </option>
                    <option value='4'>
                      {translatedTextsObject.msgFuncChange}
                    </option>
                    <option value='1'>
                      {translatedTextsObject.msgFuncCancel}
                    </option>
                    <option value='52'>
                      {translatedTextsObject.msgFuncProposedAmendment}
                    </option>
                  </Select>
                </FormControl>
              </Grid>
              {messageFunction === '52' && (
                <Grid item xs={12} md>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor={`amendment-code-label-${index}`}>
                      {translatedTextsObject.amendmentCode}
                    </InputLabel>
                    <Select
                      label={translatedTextsObject.amendmentCode}
                      inputProps={{
                        id: `amendment-code-label-${index}`
                      }}
                      native
                      onChange={(event) => setAmendmentCode(event.target.value)}
                      size='small'
                      value={amendmentCode}
                    >
                      {amendmentCodeOptions.map((option) => (
                        <option key={option.code} value={option.code}>
                          {option.desc}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md='auto'>
            <CngIconButton
              icon={['fal', expanded ? 'chevron-up' : 'chevron-down']}
              onClick={() => setExpanded((prev) => !prev)}
              size='small'
              type='outlined'
            />
          </Grid>
        </Grid>
        <Collapse in={expanded}>
          <Box marginTop={2}>
            <Grid container spacing={2}>
              {houseBills.map((houseBill, index) => (
                <React.Fragment key={index}>
                  <Grid key={index} item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Typography component='div' variant='inherit'>
                          {houseBill.ccn}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CngField label={`${translatedTextsObject.shipperName}`}>
                          <Typography component='div' variant='inherit'>
                            {houseBill.shipperName}
                          </Typography>
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {houseBills.length !== index + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Collapse>
      </Box>
    </Paper>
  )
}